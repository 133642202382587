<template>
  <div class="container" ref="printContainer">
    <div
      class="row firstPage printPage html2pdf__page-break"
      :class="savePDF === 'false' ? 'reportData' : ''"
    >
      <div
        v-if="
          companyData.logo ||
          (printSettings.show_second_logo_in_report && companyData.second_logo)
        "
        class="col-md-8 reportCompanyLogo"
      >
        <img
          :src="
            printSettings.show_second_logo_in_report && companyData.second_logo
              ? companyData.second_logo
              : companyData.logo
          "
          alt="company logo"
          @load="imageLoaded"
        />
      </div>
      <div class="row col-md-12 m-0 reportHeader">
        <div class="col-md-12" v-if="companyData.show_name_in_report">
          {{ companyData.name }}
        </div>

        <div class="col-md-12" v-if="companyData.show_bio_in_report">
          {{ companyData.bio }}
        </div>
      </div>
      <div class="row PatientData">
        <div class="col-md-12">
          <p v-if="printSettings.show_name_in_report">
            <label>Client Name: </label>{{ currentPatient.name || "" }}
          </p>
          <p v-if="printSettings.show_email_in_report">
            <label>Email: </label>{{ currentPatient.email || "" }}
          </p>
          <p v-if="printSettings.show_phone_number_in_report">
            <label>Phone Number: </label>{{ currentPatient.phone_number || "" }}
          </p>
          <p v-if="printSettings.show_claim_number_in_report">
            <label>Claim Number: </label>{{ currentPatient.claim_number || "" }}
          </p>
          <p v-if="printSettings.show_date_of_birth_in_report">
            <label>Date Of Birth: </label
            >{{
              prettyDate((currentPatient.user || {}).birth_date, "MMM D, YYYY ")
                | date("MMMM DD, YYYY")
            }}
          </p>
          <p v-if="printSettings.show_date_of_loss_in_report">
            <label>Date Of Loss: </label
            >{{
              prettyDate(currentPatient.injured_at, "MMM D, YYYY ")
                | date("MMMM DD, YYYY")
            }}
          </p>
          <p v-if="printSettings.show_report_period">
            <label>Report Dates Range: </label
            >{{
              prettyDate(range.start, "MMM D, YYYY ") +
              " - " +
              prettyDate(range.end, "MMM D, YYYY ")
            }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="content-container printPage html2pdf__page-break"
      :class="savePDF === 'false' ? 'reportData' : ''"
    >
      <div>
        <h2 class="mb-5">Activity category per session:</h2>
        <div class="chartContent">
          <div
            v-for="questionnaire in questionnaires"
            :key="questionnaire.id"
            class="chartContent-item break-inside"
          >
            <div>
              <label>Date of Session: </label
              ><span
                >{{
                  formattedDate(questionnaire.apt_start_time) +
                  " - " +
                  formattedDate(questionnaire.apt_end_time)
                }}
              </span>
            </div>
            <div>
              <label>Activity categories: </label
              ><span
                >{{
                  getActivityCategoriesName(questionnaire.activity_categories)
                    .length
                    ? getActivityCategoriesName(
                        questionnaire.activity_categories
                      )
                    : "Not specified"
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="chartData">
        <div class="reportChart charts">
          <div class="row m-0 justify-content-between dashboard-item-header">
            <h1>Pain Chart</h1>
            <h3 class="text-muted">0 = No pain &nbsp; 10 = High pain</h3>
          </div>
          <div class="d-flex justify-content-center w-100 mb-3">
            <div
              class="d-flex flex-column small border rounded px-3 mb-2 ml-auto"
            >
              <div class="font-weight-bold text-center border-bottom mb-2">
                Average Pain
              </div>
              <div>
                Client Answer:
                <b class="text-danger"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    average.pain.client || "0"
                  }}</b
                >
                <span v-if="painLevels[average.pain.client]">
                  ({{ painLevels[average.pain.client] }})
                </span>
              </div>
              <div>
                Provider observation:
                <b class="text-danger"
                  >&nbsp;&nbsp;&nbsp;{{ average.pain.provider || "0" }}</b
                >
                <span v-if="painLevels[average.pain.provider]">
                  ({{ painLevels[average.pain.provider] }})</span
                >
              </div>
            </div>
          </div>
          <img :src="chartsImages.painChart" alt="pain chart" />
          <img
            src="@/assets/img/pain-scale.png"
            alt="Pain Scale"
            class="col-12 col-lg-8 offset-lg-2 mt-4"
          />
        </div>
        <div class="chartContent">
          <h2 class="mb-5">Questionnaire Information:</h2>
          <div
            v-for="questionnaire in questionnaires"
            :key="questionnaire.id"
            class="chartContent-item break-inside"
          >
            <div>
              <label>Session period: </label
              ><span
                >{{
                  formattedDate(questionnaire.apt_start_time) +
                  " - " +
                  formattedDate(questionnaire.apt_end_time)
                }}
              </span>
            </div>
            <div>
              <label>Pain Locations: </label
              ><span
                >{{ getBodyLocations(questionnaire.body_locations) }}
              </span>
            </div>
            <div>
              <label>Pain Time: </label
              ><span>{{ painTime[questionnaire.time_pain_client] }} </span>
            </div>
            <div v-if="questionnaire.time_pain_client">
              <label>Pain Rate (client answer): </label
              ><span v-if="questionnaire.pain_rate_client == -1"
                >No Answer provided</span
              >
              <span v-if="questionnaire.pain_rate_client !== -1"
                >{{ questionnaire.pain_rate_client || "0" }} [{{
                  painLevels[questionnaire.pain_rate_client] || "-"
                }}]</span
              >
            </div>
            <div v-if="questionnaire.time_pain_client">
              <label>Pain Rate (provider observation): </label
              ><span v-if="questionnaire.pain_rate_provider == -1"
                >No Answer provided</span
              >
              <span v-if="pain_rate_provider !== -1"
                >{{ questionnaire.pain_rate_provider || "0" }} [{{
                  painLevels[questionnaire.pain_rate_provider] || "-"
                }}]</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="chartData">
        <div class="reportChart charts">
          <div class="row m-0 justify-content-between dashboard-item-header">
            <h1>Fatigue Chart</h1>
            <h3 class="text-muted">0 = No fatigue &nbsp; 10 = Exhausted</h3>
          </div>
          <div class="d-flex justify-content-center w-100 mb-3">
            <div
              class="d-flex flex-column small border rounded px-3 mb-2 ml-auto"
            >
              <div class="font-weight-bold text-center border-bottom mb-2">
                Average Fatigue
              </div>
              <div>
                Client Answer:
                <b class="text-danger"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    average.fatigue.client || "0"
                  }}</b
                >
                <span v-if="fatigueLevels[average.fatigue.client]">
                  ({{ fatigueLevels[average.fatigue.client] }})</span
                >
              </div>
              <div>
                Provider observation:
                <b class="text-danger"
                  >&nbsp;&nbsp;&nbsp;{{ average.fatigue.provider || "0" }}</b
                >
                <span v-if="fatigueLevels[average.fatigue.provider]">
                  ({{ fatigueLevels[average.fatigue.provider] }})</span
                >
              </div>
            </div>
          </div>
          <img :src="chartsImages.fatigueChart" alt="Fatigue Chart" />
          <img src="@/assets/img/fatigue-scale.png" alt="Fatigue Scale" />
        </div>
      </div>
      <div class="chartData">
        <div class="reportChart charts">
          <h1>Sleep Chart</h1>
          <img :src="chartsImages.sleepChart" alt="Sleep Chart" />
        </div>
      </div>
      <div class="chartData">
        <div class="reportChart charts">
          <div class="row m-0 justify-content-between dashboard-item-header">
            <h1>Mood Chart</h1>
            <h3 class="text-muted">0 = Low &nbsp; 10 = Wonderful</h3>
          </div>
          <div class="d-flex justify-content-center w-100 mb-3">
            <div
              class="d-flex flex-column small border rounded px-3 mb-2 ml-auto"
            >
              <div class="font-weight-bold text-center border-bottom mb-2">
                Average Mood
              </div>
              <div>
                Client Answer:
                <b class="text-danger"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    average.mood.client || "0"
                  }}</b
                >
                <span v-if="moodLevels[average.mood.client]">
                  ({{ moodLevels[average.mood.client] }})</span
                >
              </div>
              <div>
                Provider observation:
                <b class="text-danger"
                  >&nbsp;&nbsp;&nbsp;{{ average.mood.provider || "0" }}</b
                >
                <span v-if="moodLevels[average.mood.provider]">
                  ({{ moodLevels[average.mood.provider] }})</span
                >
              </div>
            </div>
          </div>
          <img :src="chartsImages.moodChart" alt="Mood Chart" />
          <img
            src="@/assets/img/mood-scale.png"
            alt="Mood Scale"
            class="col-12 col-lg-8 offset-lg-2 mt-4"
          />
        </div>
      </div>
      <div class="chartData">
        <div class="reportChart charts">
          <div
            class="row m-0 justify-content-between dashboard-item-header mb-3"
          >
            <h1 class="dashboard-item-title">Headaches Chart</h1>
            <div class="small">
              Average Headache:
              <b class="text-danger">{{ average.headache }}</b>
              <span> ({{ headacheLevels[average.headache] }})</span>
            </div>
            <div class="text-muted small">0 = No Headache &nbsp; 10 = High</div>
          </div>
          <img :src="chartsImages.headachesChart" alt="Headaches Chart" />
        </div>
      </div>
      <div class="chartData">
        <div class="reportChart charts">
          <h1>Anxiety Chart</h1>
          <div class="chart-img text-center" v-html="biChartSVGContent"></div>
          <div class="monthly-details">
            <div class="row mx-0 justify-content-between">
              <div class="d-flex align-items-center">
                <div class="mr-2 info-devider secondary-info"></div>
                <div class="info-text">
                  Non anxiety:
                  <span class="value">
                    {{ anxietyData.noAnxietyPercent.toFixed(2) }}%
                  </span>
                </div>
              </div>
              <div class="secondary-info info-tip radius-99">
                {{ anxietyData.noAnxietyCount }}
              </div>
            </div>
            <div class="row mx-0 justify-content-between mt-1">
              <div class="d-flex align-items-center">
                <div class="mr-2 info-devider main-info"></div>
                <div class="info-text">
                  Anxiety:
                  <span class="value">
                    {{ anxietyData.anxietyPercent.toFixed(2) }}%
                  </span>
                </div>
              </div>
              <div class="main-info info-tip radius-99">
                {{ anxietyData.anxietyCount }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chartData">
        <div class="reportChart charts">
          <h1>General Symptoms Observed Chart</h1>
          <img
            :src="chartsImages.generalSymptomsObservedChart"
            alt="General Symptoms Observed Chart"
          />
        </div>
        <div class="chartContent">
          <h2 class="mb-5">General Symptoms Observed Information:</h2>
          <div
            v-for="(
              generalSymptomsObserved, index
            ) in generalSymptomsObservedData"
            :key="index"
            class="chartContent-item break-inside"
          >
            <div>
              <label class="font-weight-bold">Question: </label>
              <span>{{ getGSOQuestion(generalSymptomsObserved.x) }}</span>
            </div>
            <div>
              <label class="font-weight-bold">Observed Percentage: </label>
              <span
                >{{ parseFloat(generalSymptomsObserved.y).toFixed(2) }}%</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader data-html2canvas-ignore class="noPrint" v-if="processing"
      ><b class="mt-4 text-center" v-html="processingText"></b>
    </loader>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import dateFormat from "@/utils/dateFormat";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";
import CONSTANT from "../symptoms/constants";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  data() {
    return {
      chartsImages: {},
      average: {},
      questionnaires: [],
      generalSymptomsObservedData: [],
      processing: false,
      processingText: "",
      biChartSVGContent: "",
      painLevels: CONSTANT.PAIN_LEVELS,
      fatigueLevels: CONSTANT.FATIGUE_LEVELS,
      moodLevels: CONSTANT.MOOD_LEVELS,
      headacheLevels: CONSTANT.HEADACHE_LEVELS,
    };
  },
  created() {
    this.chartsImages =
      JSON.parse(window.localStorage.getItem("chartURI")) || {};
    this.average = JSON.parse(window.localStorage.getItem("average")) || {};
    this.questionnaires = JSON.parse(
      window.localStorage.getItem("questionnaires")
    );
    this.generalSymptomsObservedData = JSON.parse(
      window.localStorage.getItem("generalSymptomsObservedData")
    );
    window.localStorage.removeItem("chartURI");
    window.localStorage.removeItem("average");
    window.localStorage.removeItem("questionnaires");
    window.localStorage.removeItem("generalSymptomsObservedData");
    window.history.replaceState("", "", "/blank");
    const vm = this;
    fetch("/images/bi-chart.svg")
      .then((response) => response.text())
      .then((data) => {
        const newData = data
          .replace(/var\(--theme-color\)/g, "#2c7be5")
          .replace(/gradient-start/g, "")
          .replace(/gradient-end/g, "");
        vm.biChartSVGContent = newData;
      })
      .catch((error) => console.error(error));
    for (let setting in this.printSettings) {
      this.printSettings[setting] =
        this.printSettings[setting] == "true" ? true : false;
    }
    this.processing = true;
    if (vm.savePDF === "false") {
      this.processingText = "<h3>Preparing report to print...</h3>";
    } else {
      this.processingText = "<h3>Preparing PDF...</h3>";
    }
    this.getCompanyProfile().then(() => {
      if (
        !(
          this.companyData.logo ||
          (this.printSettings.show_second_logo_in_report &&
            this.companyData.second_logo)
        )
      ) {
        if (vm.savePDF === "false") {
          vm.print();
        } else {
          vm.savePDFHandler().then(() => {
            window.close();
          });
        }
      }
    });
  },
  computed: {
    ...mapState({
      companyData: (state) => state.settings.companyProfile,
      currentPatient: (state) => state.patients.patient,
      mapParts: (state) => state.patients.symptoms.mapParts,
      painTime: (state) => state.patients.symptoms.painTime,
    }),
    range: function () {
      return this.$route.query.range || {};
    },
    printSettings: function () {
      return this.$route.query.printSettingsProp || {};
    },
    savePDF: function () {
      return this.$route.query.savePDF;
    },
    anxietyData: function () {
      let numberOfAnxiety = 0;
      let numberOfNoAnxiety = 0;
      for (let questionnaire of this.questionnaires) {
        if (questionnaire.anxiety_client) {
          if (questionnaire.anxiety_client === 1) {
            numberOfAnxiety++;
          } else {
            numberOfNoAnxiety++;
          }
        }
      }
      const total = numberOfAnxiety + numberOfNoAnxiety;
      if (total === 0) {
        return {
          anxietyCount: 0,
          noAnxietyCount: 0,
          noAnxietyPercent: 0,
          anxietyPercent: 0,
        };
      }
      return {
        anxietyCount: numberOfAnxiety,
        noAnxietyCount: numberOfNoAnxiety,
        noAnxietyPercent: (numberOfNoAnxiety / total) * 100,
        anxietyPercent: (numberOfAnxiety / total) * 100,
      };
    },
  },
  methods: {
    ...mapActions({
      getCompanyProfile: "settings/getCompanyProfile",
    }),
    prettyDate: function (date, format = null) {
      if (date == undefined || date == null) {
        return;
      }
      if (!format) {
        return dateFormat(date, "human");
      } else {
        return dateFormat(date, format);
      }
    },
    print: function () {
      document.title = `${this.currentPatient.name} Report`;
      this.calculateBiChartPercentage(this.anxietyData.noAnxietyPercent);
      setTimeout(() => {
        window.print();
        window.close();
      }, 1500);
    },
    savePDFHandler: function () {
      return html2pdf(this.$refs.printContainer, {
        margin: 8,
        filename: `${this.currentPatient.name} Report.pdf`,
        image: { type: "jpeg", quality: 1 },
        pagebreak: { mode: ["css", "legacy"] },
        html2canvas: {
          scale: 5,
          allowTaint: true,
          useCORS: true,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "letter", orientation: "portrait" },
      })
        .then((res) => {
          return res;
        })
        .catch(() => {
          this.fireError();
          Swal.fire({
            title: "Error",
            html: "Something went wrong...",
            icon: "error",
          }).then(() => {
            window.close();
          });
        });
    },
    getBodyLocations: function (bodyLocations) {
      const vm = this;
      return bodyLocations
        .map((item) => {
          if (item) {
            return vm.mapParts[item.loc_body - 1];
          }
        })
        .join(", ");
    },
    formattedDate: function (dateToformat, flag = false) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat)
          .add(-1 * dayjs(dateToformat).utcOffset(), "minute")
          .format(!flag ? "YYYY-MM-DD hh:mm A" : "MMM-DD hh:mm A");
      } else return "";
    },
    fireError: function () {
      this.processing = false;
      this.processingText = "";
    },
    imageLoaded: function () {
      if (this.savePDF === "false") {
        this.print();
      } else {
        this.savePDFHandler().then(() => {
          window.close();
        });
      }
    },
    getActivityCategoriesName: function (questionnaireActivityCategories) {
      return questionnaireActivityCategories
        .map((activityCategory) => {
          return activityCategory.name;
        })
        .join(", ");
    },
    getGSOQuestion: function (questions) {
      if (Array.isArray(questions)) {
        return questions.join(" ");
      } else {
        return questions;
      }
    },
    calculateBiChartPercentage: function (percentage) {
      const radius = 72;
      const centerX = 89;
      const centerY = 89;
      if (percentage == 100) {
        percentage = 99.99999;
      }

      const endAngle = (percentage / 100) * 2 * Math.PI;
      const largeArcFlag = percentage > 50 ? 1 : 0;

      const x = centerX + radius * Math.cos(endAngle - Math.PI / 2);
      const y = centerY + radius * Math.sin(endAngle - Math.PI / 2);

      const d = `M ${centerX},${
        centerY - radius
      } A ${radius},${radius} 0 ${largeArcFlag} 1 ${x},${y} L ${centerX},${centerY} Z`;
      if (document.getElementById("monthlyArcPath")) {
        document.getElementById("monthlyArcPath").setAttribute("d", d);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media print {
  @page {
    margin: 15mm;
  }
}
body {
  background-color: #fff !important;
}
.printPage,
.html2pdf__page-break {
  page-break-after: always;
  margin-top: 120px;
}
.break-inside {
  break-after: all;
}
.chartData {
  .reportChart {
    break-inside: avoid-page;
  }
  margin-top: 50px;
  img {
    width: 100%;
  }
}
.PatientData {
  margin-top: 30px;
  font-size: 19px;
  label {
    font-weight: bold;
    margin-right: 10px;
    text-transform: uppercase;
    font-size: 20px;
  }
}
.loader {
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.98);
  color: black;
  flex-direction: column;
}

.reportHeader {
  margin: 20px auto;
  text-align: center;
  font-size: 23px;
  background: #002060;
  border: 4px solid #335b74;
  color: #fff;
  line-height: 33px;
}
.content-container {
  margin: 50px auto;
  width: 100%;
  .chartContent {
    margin-top: 50px;
    .chartContent-item {
      margin-bottom: 25px;
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 10px;
      div {
        label {
          margin-right: 10px;
          font-weight: bold;
        }
      }
    }
  }
  .charts {
    background-color: white;
    border-radius: 20px;
    border: 1px solid #c1c4d0;
    padding: 36px 32px;
  }
}

.reportCompanyLogo {
  margin: 50px auto;
  text-align: center;
  img {
    height: 200px !important;
  }
}
.monthly-details {
  margin-top: 16px;

  .info-devider {
    width: 3px;
    border-radius: 1px;
    height: 15px;
  }
  .info-tip {
    padding: 4px 15px;
    font-size: 18px;
  }
  .main-info {
    background-color: #2c7be5;
    color: #ffffff;
    font-size: 18px;
  }
  .secondary-info {
    background-color: #000000;
    color: #ffffff;
  }
  .info-text {
    font-size: 18px;
  }
  .value {
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
<style lang="scss">
.chart-img {
  svg {
    max-width: 100%;
    width: 300px !important;
    height: 300px !important;
  }
}
</style>
